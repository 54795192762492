import { PrivacyControllerService, PrivacyVo } from '@/__generated__/FrontApi';
import Container from '@/components/Container';
import PageComponent from '@/components/PageComponent';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { PrivacyContainer } from '@/components/Term';
import { Tit } from '@/components/Titles';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { Link, PageProps } from 'gatsby';
import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

const PrivacyContent = styled.div`
  line-height: 1.7;
  img {
    margin: 0 auto;
  }
  table {
    th,
    td {
      word-break: break-all;
    }
  }
`;

interface PrivacyData {
  indexNumber: number;
  version: string;
  enforceDate: string;
  content: string;
  useYn: string;
  firstRegisterDate: string;
  lastModifyDate: string;
  latestVersion: string;
}

const PrivacyVersion: FC<PageProps> = ({ params: { version } }) => {
  const [privacy, setPrivacy] = useState<PrivacyData>();
  const [privacyList, setPrivacyList] = useState<PrivacyData[]>([]);

  // 개인정보 처리방침 로드
  const loadPrivacyList = useCallback(async () => {
    try {
      const {
        data: privacy,
      } = await PrivacyControllerService.getPrivacyListUsingGet();
      setPrivacy(
        privacy.filter((item: PrivacyVo) => item.version === version)[0],
      );
      setPrivacyList(
        privacy.map((item: PrivacyVo) => ({
          id: item.indexNumber,
          version: item.version,
          enforceDate: item.enforceDate,
          content: item.content,
          useYn: item.useYn,
          latestVersion: item.latestVersion,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, [version]);

  useEffect(() => {
    loadPrivacyList();
  }, [loadPrivacyList]);

  const currentKey = 0;

  return (
    <LayoutWithTitle title="개인정보 처리방침">
      <Section className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">개인정보 처리방침 v{privacy?.version}</Tit>
            </h2>
            <Tit size="s3">(시행일 {privacy?.enforceDate})</Tit>
          </SectionHeader>
          <Tit size="s1-5" color="sky" weight="normal">
            <PageComponent id="title1">
              유니세프한국위원회는 개인정보를 소중히 다루고 있습니다
            </PageComponent>
          </Tit>

          <PrivacyContainer id="tab-section">
            <div className="tab-btn">
              <Swiper
                slidesPerView={3}
                navigation={false}
                scrollbar={{ draggable: false }}
                noSwiping
                allowTouchMove={false}
                breakpoints={{
                  641: {
                    slidesPerView: 3,
                    initialSlide: currentKey,
                  },
                  320: {
                    allowTouchMove: true,
                    slidesPerView: 2.5,
                    initialSlide: currentKey,
                  },
                }}
              >
                <SwiperSlide>
                  <Link to="/privacy#tab-section" className="link-item _active">
                    개인정보 처리방침
                  </Link>
                </SwiperSlide>
                <SwiperSlide>
                  <Link to="/privacy-corp#tab-section" className="link-item">
                    위탁업체
                  </Link>
                </SwiperSlide>
                <SwiperSlide>
                  <Link to="/cctv-policy#tab-section" className="link-item">
                    영상정보처리기기 <br />
                    운영 · 관리 방침
                  </Link>
                </SwiperSlide>
              </Swiper>
            </div>
            <PrivacyContent
              dangerouslySetInnerHTML={{ __html: privacy?.content }}
            />
            <article>
              <div className="history-box">
                <ul>
                  {privacyList.map((row) => (
                    <li>
                      <Link
                        to={`/privacy/${row.version}`}
                        className="link-item"
                      >
                        유니세프한국위원회 개인정보 처리방침 v{row.version}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              <p className="tip">※ 유니세프한국위원회 문서 처리 규정</p>
            </article>
          </PrivacyContainer>
        </Container>
      </Section>
    </LayoutWithTitle>
  );
};

export default PrivacyVersion;
